import { useQuery } from 'react-query';

import { getExperimentVariantOnClient } from '../utils';

export function useClientExperiment(flagKey: string) {
  const { isLoading, data: variant } = useQuery(['clientExperiment', flagKey], () =>
    getExperimentVariantOnClient(flagKey)
  );
  return { isLoading, variant };
}
