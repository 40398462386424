import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Divider, Flex } from '@chakra-ui/react';
import { ConsentChangeEventDetails } from '@transcend-io/airgap.js-types';

import { Link } from '~/components/link';
import { trackEvent } from '~/features/analytics/track-event';
import { getViewerCountryCode, getViewerRegionCode } from '~/features/internationalization/utils/get-viewer-geo-codes';
import { isEuCountryCode } from '~/features/internationalization/utils/is-eu-country-code';
import { useBaseUrl } from '~/hooks/use-base-url';
import { useClientRender } from '~/hooks/use-client-render';

import { getLegalNavigation, getLegalText } from '../data';

export function Legal() {
  return (
    <Flex flexDirection={{ base: 'column', xl: 'row' }} gap='16px' justifyContent='space-between'>
      <LegalText />
      <LegalNavigation />
    </Flex>
  );
}

export function LegalText() {
  const { t } = useTranslation();
  const legalText = useMemo(() => getLegalText(t), [t]);

  return (
    <Box>
      <Box h='24px' />
      <Divider display={{ base: 'block', md: 'none' }} mb='24px' />
      <Box maxW={{ base: '100%', md: '640px' }}>{legalText}</Box>
    </Box>
  );
}

export function LegalNavigation() {
  const clientReady = useClientRender();
  const baseUrl = useBaseUrl();
  const { t } = useTranslation();
  const legalNavigation = useMemo(() => getLegalNavigation(t), [t]);

  return (
    <Box>
      <Box h='24px' />
      <Box
        aria-label='Legal & privacy related links.'
        as='nav'
        columnGap='24px'
        display='flex'
        flexWrap='wrap'
        rowGap='8px'
      >
        {legalNavigation.map((link) => (
          <Link
            key={link.href}
            _visited={{ color: 'black' }}
            color='black'
            href={link.isAbsolute ? link.href : `${baseUrl}${link.href}`}
            isExternal={link.isExternal}
            locale={false}
          >
            {link.label}
          </Link>
        ))}
        {clientReady && <DoNotSellMyDataButton />}
      </Box>
    </Box>
  );
}

function DoNotSellMyDataButton() {
  const { t } = useTranslation();

  const countryCode = getViewerCountryCode();
  const regionCode = getViewerRegionCode();
  const isInCalifornia = countryCode === 'US' && regionCode === 'CA';
  if (!isInCalifornia && !isEuCountryCode(countryCode!)) return null;

  function doNotSellMyDataClickHandler() {
    trackEvent({
      name: 'CCPA CTA Clicked',
      type: 'click',
      label: 'Do Not Sell My Data',
      action: 'ccpa_cta_clicked',
    });
    window?.transcend.showConsentManager({ viewState: 'CompleteOptions' });
    window?.airgap.ready((airgap) => {
      airgap.addEventListener('consent-change', (event: any) => {
        const changes = (event.detail as ConsentChangeEventDetails)?.changes;
        if (changes) {
          trackEvent({
            name: 'CCPA CTA Clicked',
            type: 'click',
            label: 'Confirm',
            action: 'pi_data_usage_confirm_clicked',
            additionalAttributes: { ...changes },
          });
        }
      });
    });
  }

  return (
    <Button
      color='black'
      fontWeight='700'
      textDecoration='underline'
      variant='link'
      _hover={{
        color: 'black',
      }}
      onClick={doNotSellMyDataClickHandler}
    >
      {t('footer.doNotSellMyData')}
    </Button>
  );
}
