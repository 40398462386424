import { useQuery } from 'react-query';

import auth from '@udacity/ureact-hoth';

import { useClientRender } from '~/hooks/use-client-render';

import { getIsConnectLearner } from '../controllers/get-is-connect-learner';

export function useIsConnectLearner() {
  const clientReady = useClientRender();
  const userKey = auth.getCurrentUserId();
  const shouldPingConnect = Boolean(clientReady && userKey);

  const { data, isLoading, isFetched } = useQuery({
    queryKey: 'connect-learner',
    queryFn: getIsConnectLearner,
    enabled: shouldPingConnect,
  });

  return {
    isConnectLearner: data,
    isLoading,
    isFetched,
  };
}
